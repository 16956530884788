<template>
  <div class="q">
      <p class="title">提示</p>
      <p class="word">当你看到这个页面的时候证明您的前后端都已经搭建并且完成了对接，可以投入使用，接下来请点击下面的按钮完成安装的最后一步</p>
      <div class="jdtbox">
        <div class="jdt" v-type="jdt"></div>
      </div>
      <button class="install" @click="install()" v-background='go'>{{go ? '安装中':'Install'}}</button>
  </div>
</template>

<script>
export default {
  data () {
    return {
      jdt: 6,
      url: '',
      go: false,
      km: ''
    }
  },
  methods: {
    async install () {
      if (!this.go) {
        const { data: apiurl } = await this.$http.get(window.location.origin + '/static/apiurl.json')
        if (apiurl.url) {
          this.jdt = 30
          this.go = true
          this.url = apiurl.url
          setTimeout(() => {
            this.getkm()
          }, 1000)
        }
      } else {
        alert('请耐心等待')
      }
    },
    async getkm (url) {
      const { data } = await this.$http.post('http://getkm.api.xudounet.com/copy/api')
      if (data.data) {
        this.jdt = 70
        this.km = data.data
        setTimeout(() => {
          this.shou()
        }, 1000)
      } else {
        alert('卡密不足，请联系官方添加卡密，官方qq群：893570125')
      }
    },
    async shou () {
      /* 将请求题拼接为json并进行base64加密 */
      const url = this.url.replace(/http:\/\//g, '').replace(/\//g, '')
      const postdata = {}
      postdata.km = this.km
      postdata.url = url
      const datapost = window.btoa(JSON.stringify(postdata))
      const { data: over } = await this.$http.post('http://shou.api.xudounet.com/tga/post', datapost)
      if (over.error_code === 1001) {
        this.jdt = 100
        setTimeout(() => {
          alert('安装成功')
          this.$router.replace('/')
        }, 2000)
      } else {
        alert('发生错误：错误码=' + over.error_code + ',请联系官方，官方qq群：893570125')
      }
    }
  },
  directives: {
    background: {
      update (el, binding) {
        if (binding.value) {
          el.style.background = 'rgb(113, 113, 113)'
        } else {
          el.style.background = 'rgb(0, 110, 255)'
        }
      },
      bind (el, binding) {
        if (binding.value) {
          el.style.background = 'rgb(113, 113, 113)'
        } else {
          el.style.background = 'rgb(0, 110, 255)'
        }
      }
    },
    type: {
      update (el, binding) {
        if (binding.value <= 100) {
          el.style.width = binding.value + '%'
        }
      },
      bind (el, binding) {
        if (binding.value <= 100) {
          el.style.width = binding.value + '%'
        }
      }
    }
  }

}
</script>

<style lang='less' scoped>
.sada{
  background-color: rgb(113, 113, 113);
}
.jdtbox{
    width: 80%;
    height: 10px;
    border-radius: 6px;
    margin: 30px auto;
    background-color: rgb(240, 240, 240);

    .jdt{
        width: 10%;
        height: 100%;
        border-radius: 5px;
        background-color: rgb(0, 123, 255);
        transition: width 2s;
    }
}
.install{
    width: 120px;
    height: 30px;
    border: none;
    border-radius: 6px;
    background-color: rgb(0, 110, 255);
    color: white;
    display: block;
    margin: 10px auto;
}
    .q{
        width: 100%;
        min-height: 800px;
        overflow: hidden;
        .title{
            width: 100%;
            text-align: center;
            color: red;
            font-size: 18px;
            font-weight: 600;
            margin-top: 100px;
        }
        .word{
            width: 80%;
            margin: 10px auto;
            text-align: center;
            font-size: 15px;
            font-weight: 600;
        }
        a{
            width: 100px;
            height: 30px;
            font-size: 13px;
            font-weight: 600;
            color: white;
            background-color: rgb(62, 169, 0);
            border-radius: 7px;
            display: block;
            margin: 10px auto;
            line-height: 30px;
            text-align: center;
        }
    }
</style>
