import Vue from 'vue'
import VueRouter from 'vue-router'

/* 导入组件 */
import userindex from '@/components/user/index.vue'
import userpost from '@/components/user/post.vue'
import usergrade from '@/components/user/grade.vue'
import userchain from '@/components/user/chain.vue'
import userdateils from '@/components/user/dateils.vue'
import usersch from '@/components/user/serch'

/* 导入后端页面的hash地址 */
import adminhash from '@/router/admin.js'
/* 导入未授权页面 */
import sos from '@/components/admin/ko.vue'

/* 导入admin相关组件 */
import adminindex from '@/components/admin/index.vue'
import adminlogin from '@/components/admin/login.vue'
import adminhome from '@/components/admin/view/home'
import adminuser from '@/components/admin/view/user'
import admingaiuser from '@/components/admin/component/gaiuser'
import adminnewuser from '@/components/admin/view/newuser'
import adminnewusers from '@/components/admin/component/newuser'
import adminexamine from '@/components/admin/view/examine'
import adminchain from '@/components/admin/view/chain'
import adminover from '@/components/admin/view/over'
import adminexaminda from '@/components/admin/component/examine'
import postlog from '@/components/user/postlog'

Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    redirect: '/index'
  },
  { path: '/index', component: userindex },
  { path: '/post', component: userpost },
  { path: '/grade', component: usergrade },
  { path: '/chain', component: userchain },
  { path: '/sch', component: usersch },
  { path: '/post/log', component: postlog },
  { path: '/sos', component: sos },
  { path: '/dateils', component: userdateils, props: true },
  {
    path: '/admin',
    component: adminindex,
    redirect: '/admin/home',
    children: [
      { path: 'home', component: adminhome },
      { path: 'gaiuser/:type', component: admingaiuser, props: true },
      { path: 'newuser', component: adminnewuser },
      { path: 'examine', component: adminexamine },
      { path: 'examinda', component: adminexaminda },
      { path: 'chain', component: adminchain },
      { path: 'over', component: adminover },
      { path: 'newusers/:type', component: adminnewusers, props: true },
      { path: 'user', component: adminuser }
    ]
  },
  { path: '/admin/login', component: adminlogin }
]

const router = new VueRouter({
/*   mode: 'history',
  base: process.env.BASE_URL, */
  routes
})
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

router.beforeEach((to, from, next) => {
  /* 导航守卫拦截后台管理组件 */
  if (adminhash.indexOf(to.path) > -1) {
    if (localStorage.getItem('xdnet_token')) {
      next()
    } else {
      /* 若没有登陆，则强制跳转到登陆页面 */
      next('/admin/login')
    }
  } else {
    next()
  }

  /*
  to： 是将要访问的路由对象信息
  from： 是将要离开的路由对象信息
  next： 是一个函数 调用 next() 表示放行
  */
})
export default router
