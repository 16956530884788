<template>
  <div class="q" v-title :data-title="title">
    <!-- 路由模块 -->
    <router-view></router-view>
    <!-- 演示站模块 -->
    <!-- <Dom></Dom> -->
  </div>
</template>

<script>
/* import Dom from '@/components/dom.vue' */
export default {
  data () {
    return {
      title: ''
    }
  },
  components: {
    /* Dom */
  },
  methods: {
    /* 获取数据 */
    async gets () {
      const { data: over } = await this.$http.post('/user/over')
      if (over.error_code === 1001) {
        /* 本地存储token */
        localStorage.setItem('xdnet_con', JSON.stringify(over.data))
      } else if (over.error_code === 1002) {
        /* 若判断为禁用直接导航警告页 */
        this.$router.push('/sos')
      } else {
        alert('未知错误' + over.error_code)
      }
    }
  },

  created () {
    this.gets()
    this.title = JSON.parse(localStorage.getItem('xdnet_con')).tilte + '-' + JSON.parse(localStorage.getItem('xdnet_con')).viceTitle
  }
}
</script>

<style lang='less' scoped>
/deep/.q{
  max-width: 400px;
  margin: 0 auto;
  position: relative;
}

.adaw{
  position: fixed;
  bottom: 100px;
  right: 10px;
  z-index: 20000;

  .xuan{  display: block;
    padding: 8px;
    background-color: rgb(54, 131, 255);
    color: white;
    border-radius: 6px;
    box-shadow: 2px 2px 5px rgb(202, 202, 202);
    font-size: 15px;
    margin-top: 7px;
  }
}

</style>
