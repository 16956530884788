<template>
  <div class="q" v-title :data-title="title">
    <!-- 顶部标题栏 -->
    <div class="topbar">
      <p class="toptitle">档案信息</p>
      <div class="goback" @click="$router.back()">
        <i class="iconfont icon-back"></i>
      </div>
    </div>
    <!-- 信息部分 -->
    <div class="dateils">
      <ul class="date-box">
        <li class="list">
          <p>QQ号:</p>
          <p class="list-x">{{ zhengju.qq }}</p>
        </li>
        <li class="list">
          <p>微信号:</p>
          <p class="list-x">{{ zhengju.wx }}</p>
        </li>
        <li class="list">
          <p>手机号:</p>
          <p class="list-x">{{ zhengju.ipone }}</p>
        </li>
      </ul>
      <ul class="date-box">
        <li class="list">
          <p v-if="zhengju.grade == 'C' || zhengju.grade == 'D'">诈骗金额:</p>
          <p v-if="zhengju.grade == 'A'">保证金额:</p>
          <p v-if="zhengju.grade !== 'A'" class="list-x">{{ zhengju.money }}元</p>
          <p v-if="zhengju.grade == 'A'" class="list-x">{{ zhengju.att }}元</p>
        </li>
        <li class="list">
          <p>信誉等级:</p>
          <p class="list-x">{{ zhengju.grade }}级</p>
        </li>
        <li class="list" v-if="zhengju.time">
          <p>存档时间:</p>
          <p class="list-x">{{ zhengju.time }}</p>
        </li>
      </ul>
      <ul class="date-box">
        <li class="list-xx" v-if="zhengju.deta">
          <p class="xx-title">详细情况</p>
          <p class="list-s">{{ zhengju.deta }}</p>
        </li>
      </ul>
      <ul class="date-box">
        <li class="list-img list-xx" v-if="zhengju.img">
          <p class="xx-title">证据截图</p>
          <ul class="formUl">
            <li v-for="(itme,index) in zhengju.img" :key="itme.id" @click='golook(index)'>
              <img :src="itme" alt="" />
            </li>
          </ul>
        </li>
      </ul>
      <!-- 申诉入口 -->
      <div class="obs" @click='postconfig()'>
        <p>我要申诉</p>
      </div>
      <!-- 大图查看 -->
      <div class="imgs" v-if="imgstype" @click='imgstype = !imgstype'>
        <img :src="imgs" alt="">
      </div>
      <!-- 印章部分 -->
      <div class="yz">
        <img
          src="http://img.xudounet.com/dou/grade/A.png"
          alt=""
          class="list-bq"
          v-if="zhengju.grade == 'A'"
        />
        <img
          src="http://img.xudounet.com/dou/grade/B.png"
          alt=""
          class="list-bq"
          v-if="zhengju.grade == 'B'"
        />
        <img
          src="http://img.xudounet.com/dou/grade/C.png"
          alt=""
          class="list-bq"
          v-if="zhengju.grade == 'C'"
        />
        <img
          src="http://img.xudounet.com/dou/grade/D.png"
          alt=""
          class="list-bq"
          v-if="zhengju.grade == 'D'"
        />
      </div>
    </div>
    <!-- 底部版权组件 -->
    <conps></conps>
  </div>
</template>

<script>
import { Toast } from 'vant'
export default {
/*   props: ['qq', 'wx', 'ip', 'money', 'grade', 'time', 'date'], */
  data () {
    return {
      zhengju: '',
      title: '',
      imgs: 'http://110api.ty.xudounet.com/uploads/779e00f4ee05360cbe53d5bfabf0c89c.png',
      imgstype: false
    }
  },
  methods: {
    async postconfig () {
      const { data: over } = await this.$http.post('/user/over')
      if (over.error_code === 1001) {
        console.log(over.data.service)
        location.href = over.data.service
      } else if (over.error_code === 1002) {
        this.$router.push('/sos')
      } else {
        Toast('未知错误' + over.error_code)
      }
    },
    golook (i) {
      /*       console.log(i) */
      this.imgs = this.zhengju.img[i]
      this.imgstype = !this.imgstype
    }
  },
  created () {
    this.zhengju = JSON.parse(localStorage.getItem('xdnet_dateils'))
    /*     console.log(this.zhengju) */
    this.title = JSON.parse(localStorage.getItem('xdnet_con')).tilte + '-档案查看'
  }
}
</script>

<style lang="less" scoped>
.imgs{
  width: 100%;
  position: fixed;
  top: 0;
  background-color: rgba(0, 0, 0, 0.338);
  z-index: 100;
  height: 1000px;
/*   visibility: hidden; */
  img{
    display: block;
    width: 70%;
    margin: 100px auto;
    border-radius: 9px;
    height: 600px;
  }
}
.q {
  width: 100%;
  min-height: 900px;
  background-color: rgb(247, 247, 247);
  .obs {
    width: 60px;
    height: 60px;
    border-radius: 150px;
    box-shadow: 2px 2px 5px rgb(198, 198, 198);
    background-color: rgb(38, 164, 0);
    position: fixed;
    bottom: 100px;
    right: 10px;
    p {
      width: 100%;
      height: 100%;
      text-align: center;
      font-size: 12px;
      color: white;
      font-weight: 600;
      line-height: 60px;
    }
  }
  .dateils {
    width: 100%;
    .yz{
      width: 150px;
      position: absolute;
      top: 200px;
      right: 30%;
      img{
        width: 100%;
      }
    }
    .date-box {
      width: 95%;
      /*       height: 40px; */
      /*       display: flex; */
      margin: 10px auto;
      border-radius: 6px;
      background-color: white;
      .list-xx {
        width: 90%;
        /*         height: 40px; */
        margin: 0 auto;
        position: relative;
        overflow: hidden;
        .xx-title {
          width: 100%;
          height: 40px;
          text-align: center;
          line-height: 40px;
          font-size: 14px;
          font-weight: 600;
        }
        .list-s {
          width: 100%;
          /*           min-height: 40px; */
          font-weight: 500;
          color: rgb(90, 90, 90);
          font-size: 13px;
          margin-bottom: 10px;
        }
        .formUl {
          width: 100%;
          display: flex;
          /*           justify-content: space-between; */
          flex-wrap: wrap;
          li {
            width: 30%;
            height: 130px;
            overflow: hidden;
            border-radius: 5px;
            margin: 5px;
            box-shadow: 2px 2px 5px rgb(241, 241, 241);
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
      .list {
        width: 90%;
        height: 40px;
        margin: 0 auto;
        display: flex;
        position: relative;
        p {
          line-height: 40px;
          text-align: center;
          font-size: 14px;
          font-weight: 600;
        }
        .list-x {
          position: absolute;
          right: 0;
          font-weight: 500;
          color: rgb(90, 90, 90);
        }
      }
    }
  }
  .topbar {
    width: 100%;
    height: 50px;
    background-color: rgb(61, 116, 255);
    position: relative;
    .toptitle {
      width: 100%;
      height: 50px;
      line-height: 50px;
      text-align: center;
      font-size: 14px;
      color: rgb(255, 255, 255);
      font-weight: 600;
    }
    .goback {
      width: 50px;
      height: 50px;
      text-align: center;
      line-height: 50px;
      position: absolute;
      top: 0;
      left: 0;
      i {
        font-size: 22px;
      }
    }
  }
}
</style>
