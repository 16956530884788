<template>
  <div class="q">
    <div class="back">
      <ul class="gradeul">
        <li class="list-ban" v-for="itme in grades" :key="itme.id">
          <div class="left">
            <p class="title-p">{{ itme.title }}等级</p>
            <p class="title-s">{{ itme.grade }}级</p>
            <p class="banbjs">
              {{ itme.xjs }}
            </p>
          </div>
          <div class="reg">
            <div class="box">
              <img :src="itme.img" alt="" />
            </div>
          </div>
        </li>
      </ul>
    </div>

    <!-- 底部版权组件 -->
    <conps></conps>
    <!-- 底部导航组件 -->
    <bottomnav></bottomnav>
  </div>
</template>

<script>
export default {
  data () {
    return {
      grades: [
        {
          id: 1,
          title: '信誉良好',
          grade: 'A',
          img: 'http://img.xudounet.com/dou/grade/A.png',
          xjs: '信誉良好，并且已经缴纳一定的风险保证金，可以选择一定的信任'
        },
        {
          id: 2,
          title: '无不良记录',
          grade: 'B',
          img: 'http://img.xudounet.com/dou/grade/B.png',
          xjs: '系统暂未收录有关此人的信息，目前没有违规的记录'
        },
        {
          id: 3,
          title: '疑似诈骗',
          grade: 'C',
          img: 'http://img.xudounet.com/dou/grade/C.png',
          xjs: '已被举报可能存在诈骗行为，没有证据，建议不要相信'
        },
        {
          id: 4,
          title: '确认诈骗',
          grade: 'D',
          img: 'http://img.xudounet.com/dou/grade/D.png',
          xjs: '确认存在诈骗行为，并且有充足的证据，不要相信他'
        }
      ]
    }
  }
}
</script>

<style lang="less" scoped>
.q {
  width: 100%;
  min-height: 800px;
  background-color: rgb(247, 247, 247);
  .back {
    width: 96%;
    height: 100%;
    /*     background-color: white; */
    /*     border-radius: 5px; */
    margin: 10px auto;
    overflow: hidden;
    padding-top: 140px;
    background: url(../../assets/image/gradeback.png);
    background-size: 100%;
    padding-bottom: 0;
    border-radius: 5px;
    .gradeul {
      width: 100%;
      height: 100%;
      background-color: white;
      border-radius: 5px;
      padding-top: 20px;
      padding-bottom: 20px;
      .list-ban {
        width: 94%;
        border-radius: 5px;
        background-color: white;
        margin: 5px auto;
        display: flex;
        box-shadow: 2px 2px 5px rgb(243, 243, 243);
      }
      .banbjs {
        width: 100%;
        font-size: 12px;
        color: rgb(96, 96, 96);
        margin-top: 20px;
      }
    }
  }
  /* .list {
  background-color: white;
  border-radius: 6px;
  display: flex;
} */
  .left {
    width: 100%;
    height: 100%;
    padding: 20px;
  }
  .title-p {
    font-size: 14px;
    color: #575757;
  }
  .title-s {
    font-size: 18px;
    color: #363636;
    font-weight: 700;
    margin-top: 20px;
  }
  .reg {
    width: 100px;
    height: 100px;
    position: relative;
  }
  .box {
    width: 100px;
    height: 50px;
    position: absolute;
    top: 20px;
    right: 20px;
    border-radius: 10px;
    text-align: center;
    line-height: 50px;
  }
  .box img {
    width: 100%;
    /*   height: 100%; */
  }
}
</style>
