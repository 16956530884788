<template>
  <div class="q">
    <div class="back">
      <ul class="chain">
        <li class="chainlist" v-for="itme in chains" :key="itme.img">
          <a :href="itme.url">
            <img :src="itme.img" alt="" />
            <div class="listbom">
              <p>{{ itme.title }}</p>
            </div>
          </a>
          <div class="baoz">
            <p>保证金：{{itme.money}}元</p>
          </div>
        </li>
      </ul>
    </div>
    <!-- 底部版权组件 -->
    <conps></conps>
    <!-- 底部导航组件 -->
    <bottomnav></bottomnav>
  </div>
</template>

<script>
/* import qs from 'qs' */
import { Toast } from 'vant'
export default {
  data () {
    return {
      chains: []
    }
  },
  methods: {
    async gets () {
      const { data: over } = await this.$http.post('/user/chain')
      if (over.error_code === 1001) {
        this.chains = over.data
      } else if (over.error_code === 1002) {
        this.$router.push('/sos')
      } else if (over.data === 'token不存在数据库中--->已拦截') {
        localStorage.removeItem('xdnet_token')
        this.$router.push('/admin')
      } else {
        Toast('未知错误' + over.error_code)
      }
    }
  },
  created () {
    this.gets()
  }
}
</script>

<style lang="less" scoped>
.q {
  width: 100%;
  min-height: 800px;
  background-color: rgb(247, 247, 247);
  .back {
    width: 96%;
    height: 100%;
    /*     background-color: white; */
    /*     border-radius: 5px; */
    margin: 10px auto;
    overflow: hidden;
    padding-top: 140px;
    background: url(../../assets/image/chainback.png);
    background-size: 100%;
    padding-bottom: 0;
    border-radius: 5px;
    .chain {
      width: 100%;
      min-height: 600px;
      background-color: white;
      padding-top: 20px;
      padding-bottom: 20px;
      border-radius: 6px;
      position: relative;
      .baoz {
        /*         width: 100px; */
        height: 30px;
        position: absolute;
        top: 0;
        right: 0;
        background-color: rgba(60, 156, 0, 0.646);
        /*         border-radius: 5px; */
        border-bottom-left-radius: 10px;
        overflow: hidden;
        padding-left: 10px;
        padding-right: 10px;
        p {
          width: 100%;
          height: 100%;
          line-height: 30px;
          text-align: center;
          color: white;
          font-weight: 600;
          font-size: 12px;
        }
      }
      .chainlist {
        width: 94%;
        height: 130px;
        overflow: hidden;
        margin: 6px auto;
        background-color: white;
        border-radius: 5px;
        position: relative;
        img {
          width: 100%;
          height: 100%;
        }
        .listbom {
          width: 100%;
          height: 25px;
          position: absolute;
          bottom: 0;
          background-color: rgba(0, 0, 0, 0.413);
          p {
            height: 20px;
            line-height: 25px;
            margin-left: 10px;
            margin-right: 10px;
            color: white;
            font-size: 12px;
            display: inline-block;
            text-align: center;
            font-weight: 600;
          }
        }
      }
    }
  }
}
</style>
