<template>
  <div class="q" v-title :data-title="title">
      <ul class="box">
        <li>
          <p>网站标题:</p>
          <input type="text" name="" id="" placeholder="请输入网站标题" v-model="conps.tilte">
        </li>
        <li>
          <p>网站副标题:</p>
          <input type="text" name="" id="" placeholder="请输入网站副标题" v-model="conps.viceTitle">
        </li>
        <li>
          <p>网站版权:</p>
          <input type="text" name="" id="" placeholder="请输入网站版权" v-model="conps.copyright">
        </li>
        <li>
          <p>网站备案号:</p>
          <input type="text" name="" id="" placeholder="请输入网站备案号" v-model="conps.filing">
        </li>
        <li>
          <p>客服链接:</p>
          <input type="text" name="" id="" placeholder="请输入客服链接" v-model="conps.service">
        </li>
        <button class="buts" @click='posts'>保存</button>
      </ul>
  </div>
</template>

<script>
import { Toast } from 'vant'
import qs from 'qs'
export default {
  data () {
    return {
      title: '',
      conps: {
        tilte: '',
        viceTitle: '',
        copyright: '',
        filing: '',
        service: ''
      }
    }
  },
  methods: {
    /* 修改数据 */
    async posts () {
      this.conps.token = localStorage.getItem('xdnet_token')
      const { data: over } = await this.$http.post('admin/overnet', qs.stringify(this.conps))
      /* 数据判断逻辑 */
      if (over.error_code === 1001) {
        Toast.success('操作成功')
      } else if (over.data === 'token不存在数据库中--->已拦截') {
        localStorage.removeItem('xdnet_token')
        this.$router.push('/admin')
      } else if (over.error_code === 1007) {
        Toast('请将数据填写完整')
      } else if (over.error_code === 1002) {
        this.$router.push('/sos')
      } else {
        Toast('未知错误' + over.error_code)
      }
    },
    /* 获取数据 */
    async gets () {
      const { data: over } = await this.$http.post('/user/over')
      if (over.error_code === 1001) {
        this.conps.tilte = over.data.tilte
        this.conps.viceTitle = over.data.viceTitle
        this.conps.copyright = over.data.copyright
        this.conps.filing = over.data.filing
        this.conps.service = over.data.service
      } else if (over.error_code === 1007) {
        Toast('请将数据填写完整')
      } else if (over.error_code === 1002) {
        this.$router.push('/sos')
      } else {
        Toast('未知错误' + over.error_code)
      }
    }
  },
  created () {
    this.gets()
    this.title = JSON.parse(localStorage.getItem('xdnet_con')).tilte + '-站点设置'
  }
}
</script>

<style lang='less' scoped>
.buts{
  width: 80px;
  height: 26px;
  border: none;
  border-radius: 5px;
  background-color: rgb(21, 122, 255);
  color: white;
  margin: 10px auto;
  font-size: 14px;
  font-weight: 600;
  display: block;
  margin-bottom: 20px;
}
  .q{
    width: 100%;
    min-height: 800px;
    background-color: rgb(248, 248, 248);
    overflow: hidden;
    .box{
      width: 95%;
      margin: 10px auto;
      background-color: white;
      border-radius: 7px;
      overflow: hidden;
      li{
        width: 95%;
        height: 45px;
        display: flex;
        margin: 6px auto;
        font-size: 13px;
        p{
          height: 45px;
          line-height: 45px;
          text-align: center;
          width: 80px;
        }
        input{
          width: 80%;
          border: none;
        }
      }
    }
  }
</style>
