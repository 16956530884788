<template>
  <div class="q">
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="gets"
      :error.sync="error"
      error-text="请求失败，点击重新加载"
    >
    <userlist v-for="itme in lists"
        :key="itme.id"
        @click="listclick(itme)" :lists='itme'></userlist>
    </van-list>
  </div>
</template>

<script>
import qs from 'qs'
import { Toast } from 'vant'
import userlist from '@/components/admin/component/examlist.vue'
export default {
  data () {
    return {
      zh: '',
      lxboxr: 1,
      lists: [],
      page: 1,
      loading: false,
      finished: false,
      error: false
    }
  },
  methods: {
    /* 点击列表事件 */
    listclick (itme) {
      /*       console.log(itme.id) */
      /* const imgs = JSON.stringify(itme) */
      // eslint-disable-next-line
      /* localStorage.setItem("xdnet_dateils", imgs); */
      /* this.$router.push('/dateils') */
    },
    /* 获取数据函数 */
    async gets () {
      const datapost = {}
      datapost.page = this.page
      datapost.token = localStorage.getItem('xdnet_token')
      const { data: over } = await this.$http.post(
        'admin/examinelist',
        qs.stringify(datapost)
      )
      if (over.error_code === 1001) {
        if (over.data.length < 10) {
          this.finished = true
        }
        /* 合并原有数组数据 */
        this.lists = [...this.lists, ...over.data]
        /* 页码数自动加1 */
        this.page += 1
        this.loading = false
      } else if (over.data === 'token不存在数据库中--->已拦截') {
        localStorage.removeItem('xdnet_token')
        this.$router.push('/admin/login')
      } else if (over.error_code === 1007) {
        Toast('请将数据填写完整')
      } else if (over.error_code === 1002) {
        this.$router.push('/sos')
      } else {
        this.loading = false
        this.error = true
      }
      /* if (over.error_code === 1001) {
        this.list = over.data
      } */
    }
  },
  created () {
    /*     this.gets() */
  },
  components: {
    userlist
  }
}
</script>

<style lang="less" scoped>
.butts{
    position: absolute;
    bottom: 10px;
    right: 10px;
    display: flex;
    button{
        width: 50px;
        height: 23px;
        border: none;
        border-radius: 6px;
        margin: 5px;
        color: white;
        font-size: 13px;
    }
    .postg{
        background-color: rgb(13, 152, 0);
    }
    .deleteg{
        background-color: rgb(255, 51, 51);
    }
}
.plist-li {
  width: 100%;
  height: 110px;
  border-radius: 5px;
  box-shadow: 4px 4px 10px rgb(241, 241, 241);
  margin-bottom: 9px;
  display: flex;
  position: relative;
  overflow: hidden;
  background-color: white;
}
.list-i {
  width: 100px;
  height: 100%;
  text-align: center;
  line-height: 100px;
}
.list-t {
  border-radius: 150px;
  width: 35px;
  height: 35px;
  background: rgb(124, 170, 255);
  text-align: center;
  display: inline-block;
}
.list-bq {
  width: 90px;
  /*  height: 90px; */
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10;
}
.list-x {
  width: 100%;
  height: 100%;
}
.hm-w {
  margin-top: 19px;
}
.hm {
  width: 100%;
  height: 20px;
  line-height: 20px;
  /* text-align: center; */
  color: rgb(0, 0, 0);
  font-size: 15px;
  /* font-weight: 600; */
  text-shadow: 2px 2px 5px rgb(201, 201, 201);
  /* margin-top: 30px; */
}
.rq {
  /* background: rgb(224, 224, 224); */
  border-radius: 5px;
  width: 200px;
  color: rgb(58, 58, 58);
  font-size: 13px;
  text-align: center;
  height: 30px;
  line-height: 30px;
  position: absolute;
  bottom: 0;
  right: 0;
}
.bot {
  width: 100%;
  height: 35px;
  line-height: 40px;
  text-align: center;
  background-color: white;
  box-shadow: 4px 4px 10px rgb(241, 241, 241);
  border-radius: 10px;
}
.ddl {
  font-size: 12px;
  display: none;
  width: 100%;
  text-align: center;
}
</style>
