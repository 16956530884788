<template>
  <div class="q" v-title :data-title="title">
    <chainlist v-for="itme in chains" :key="itme.id" :shus="itme"></chainlist>
    <div class="buys">
      <button class="butt" @click='dis = !dis'>新增诚信商家</button>
    </div>
    <!-- 弹窗 -->
    <div class="objbox" v-if="dis">
      <div class="obj">
        <p class="title">增加信息</p>
        <p class="int">
          URL地址:
          <input
            type="text"
            v-model="shus.url"
            placeholder="请输入点击跳转地址"
          />
        </p>
        <p class="int">
          IMG地址:
          <input
            type="text"
            v-model="shus.img"
            placeholder="请输入广告图直链"
          />
        </p>
        <p class="int">
          广告标题:
          <input
            type="text"
            v-model="shus.title"
            placeholder="请输入广告标题"
          />
        </p>
        <p class="int">
          保证金额:
          <input
            type="text"
            v-model="shus.money"
            placeholder="请输入广告金额"
          />
        </p>
        <button class="but" @click='newpost'>增加</button>
        <div class="back" @click='dis = !dis'>
            <i class="iconfont icon-guanbi-copy" ></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import chainlist from '@/components/admin/component/chainlist.vue'
import qs from 'qs'
import { Toast } from 'vant'

export default {
  data () {
    return {
      chains: [],
      dis: false,
      title: '',
      shus: {
        img: '',
        title: '',
        money: '',
        url: '',
        token: ''
      }
    }
  },
  methods: {
    /* 增加信息 */
    async newpost () {
      this.shus.token = localStorage.getItem('xdnet_token')
      const { data: over } = await this.$http.post('/admin/chainpost', qs.stringify(this.shus))
      /* 数据判断逻辑 */
      if (over.error_code === 1001) {
        Toast.success('操作成功')
        this.$router.go(0)
      } else if (over.data === 'token不存在数据库中--->已拦截') {
        localStorage.removeItem('xdnet_token')
        this.$router.push('/admin')
      } else if (over.error_code === 1007) {
        Toast('请将数据填写完整')
      } else if (over.error_code === 1002) {
        this.$router.push('/sos')
      } else {
        Toast('未知错误' + over.error_code)
      }
    },
    /* 修改信息 */
    /* 获取信息 */
    async gets () {
      const { data: over } = await this.$http.post('/user/chain')
      /* console.log(over) */
      if (over.error_code === 1001) {
        this.chains = over.data
      } else if (over.error_code === 1002) {
        this.$router.push('/sos')
      } else if (over.data === 'token不存在数据库中--->已拦截') {
        localStorage.removeItem('xdnet_token')
        this.$router.push('/admin')
      } else {
        Toast('未知错误' + over.error_code)
      }
    }
  },
  created () {
    this.gets()
    this.title = JSON.parse(localStorage.getItem('xdnet_con')).tilte + '-诚信商家管理'
  },
  components: {
    chainlist
  }
}
</script>

<style lang="less" scoped>
.objbox {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.349);
  position: fixed;
  top: 0;
  .obj {
      position: relative;
      .back{
          width: 50px;
          height: 50px;
          line-height: 50px;
          text-align: center;
          position: absolute;
          top: 0;
          right: 0;
          i{
              font-size: 17px;
          }
      }
    .but {
      width: 80px;
      height: 25px;
      border-radius: 6px;
      border: none;
      background-color: rgb(19, 129, 255);
      color: white;
      font-size: 13px;
      display: block;
      margin: 10px auto;
    }
    width: 70%;
    background-color: white;
    padding: 15px;
    margin: 200px auto;
    border-radius: 5px;
    .int {
      height: 40px;
      display: flex;
      line-height: 40px;
      text-align: center;
      font-size: 12px;
      color: rgb(91, 91, 91);
      input {
        border: none;
        /*         width: 100%; */
        margin-left: 10px;
      }
    }
    .title {
      width: 100%;
      font-size: 14px;
      font-weight: 600;
      text-align: center;
      margin-bottom: 10px;
    }
  }
}
.buys {
  width: 100%;
  height: 50px;
  background-color: white;
  position: fixed;
  bottom: 0px;
  left: 50%;
  transform: translate(-50%);
  .butt {
    width: 100px;
    height: 30px;
    margin: 10px auto;
    border-radius: 6px;
    border: none;
    background-color: rgb(37, 173, 0);
    font-size: 13px;
    color: white;
    display: block;
  }
}
</style>
