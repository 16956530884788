<template>
  <li class="plist-li" @click='listclick'>
    <div class="list-i">
      <img
        :src="
          'http://q.qlogo.cn/headimg_dl?dst_uin=' +
          lists.qq +
          '&spec=640&img_type=jpg'
        "
        alt=""
        class="list-t"
      />
    </div>
    <div class="list-x">
      <div class="hm-w">
        <p class="hm">
          <span>QQ号</span>：<span>{{ lists.qq }}</span>
        </p>
        <p class="hm">
          <span>微信号</span>：<span>{{ lists.wx }}</span>
        </p>
      </div>
      <!-- <p class="rq">
            <span>存档日期</span>：<span>{{ itme.time }}</span>
          </p> -->
    </div>
<!--     <img
      src="https://s1.ax1x.com/2022/04/24/L5Dmb6.png"
      alt=""
      class="list-bq"
      v-if="lists.grade == 'A'"
    />
    <img
      src="https://s1.ax1x.com/2022/04/24/L5Bb4g.png"
      alt=""
      class="list-bq"
      v-if="lists.grade == 'B'"
    />
    <img
      src="https://s1.ax1x.com/2022/04/24/L5B7E8.png"
      alt=""
      class="list-bq"
      v-if="lists.grade == 'C'"
    />
    <img
      src="https://s1.ax1x.com/2022/04/24/L5BwcR.png"
      alt=""
      class="list-bq"
      v-if="lists.grade == 'D'"
    /> -->
    <img src="../../../assets/image/list-s.png" alt="" class="list-bq" />
    <div class="butts">
      <!-- <button class="postg" @click="postgs">修改</button> -->
      <button class="deleteg" @click="deletes">去审核</button>
    </div>
  </li>
</template>

<script>
import { Dialog, Toast } from 'vant'

import qs from 'qs'
export default {
  props: ['lists'],
  data () {
    return {
      zh: '',
      lxboxr: 1
    }
  },
  methods: {
    /* 点击列表事件 */
    listclick (itme) {
      /*       console.log(itme.id) */
      /*       const imgs = JSON.stringify(itme) */
      // eslint-disable-next-line
      localStorage.setItem("xdnet_examine", JSON.stringify(this.lists));
      this.$router.push('/admin/examinda')
    },
    postgs () {
      const datas = {}
      datas.gid = this.lists.gid
      if (this.lists.grade !== 'A') {
        datas.type = 1
      } else {
        datas.type = 2
      }
      localStorage.setItem('userlist_config', JSON.stringify(datas))
      this.$router.push('/admin/gaiuser/' + this.lists.grade)
    },
    /* 删除人员信息处理函数 */
    async deletefun () {
      const postData = {}
      postData.token = localStorage.getItem('xdnet_token')
      postData.gid = this.lists.gid
      const { data: over } = await this.$http.post('/admin/userdelete', qs.stringify(postData))
      /* 数据判断逻辑 */
      if (over.error_code === 1001) {
        Toast.success('操作成功')

        this.$router.go(0)
      } else if (over.data === 'token不存在数据库中--->已拦截') {
        localStorage.removeItem('xdnet_token')
        this.$router.push('/admin')
      } else if (over.error_code === 1007) {
        Toast('请将数据填写完整')
      } else if (over.error_code === 1002) {
        this.$router.push('/sos')
      } else {
        Toast('未知错误' + over.error_code)
      }
    }
    /* deletes () {
      Dialog.confirm({
        title: '确认删除',
        message: '请您确定是自主删除并非误触'
      }).then(() => {
        this.deletefun()
      }).catch(() => {

      })
    } */
  },
  created () {
    /*     this.gets() */
  },
  components: {
    [Dialog.Component.name]: Dialog.Component
  }
}
</script>

<style lang="less" scoped>
.butts {
  position: absolute;
  bottom: 10px;
  right: 10px;
  display: flex;
  z-index: 10;
  button {
    width: 50px;
    height: 23px;
    border: none;
    border-radius: 6px;
    margin: 5px;
    color: white;
    font-size: 13px;
  }
  .postg {
    background-color: rgb(13, 152, 0);
  }
  .deleteg {
    background-color: rgb(255, 156, 8);
  }
}
.plist-li {
  width: 100%;
  height: 70px!important;
  border-radius: 5px;
  box-shadow: 4px 4px 10px rgb(241, 241, 241);
  margin-bottom: 9px;
  display: flex;
  position: relative;
  overflow: hidden;
  background-color: white;
}
.list-i {
  width: 100px;
  height: 100%;
  text-align: center;
  line-height: 100px;
}
.list-t {
  border-radius: 150px;
  width: 35px;
  height: 35px;
  background: rgb(124, 170, 255);
  text-align: center;
  display: inline-block;
}
.list-bq {
  width: 90px;
  /*  height: 90px; */
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
}
.list-x {
  width: 100%;
  height: 100%;
}
.hm-w {
  margin-top: 19px;
}
.hm {
  width: 100%;
  height: 20px;
  line-height: 20px;
  /* text-align: center; */
  color: rgb(0, 0, 0);
  font-size: 15px;
  /* font-weight: 600; */
  text-shadow: 2px 2px 5px rgb(201, 201, 201);
  /* margin-top: 30px; */
}
.rq {
  /* background: rgb(224, 224, 224); */
  border-radius: 5px;
  width: 200px;
  color: rgb(58, 58, 58);
  font-size: 13px;
  text-align: center;
  height: 30px;
  line-height: 30px;
  position: absolute;
  bottom: 0;
  right: 0;
}
.bot {
  width: 100%;
  height: 35px;
  line-height: 40px;
  text-align: center;
  background-color: white;
  box-shadow: 4px 4px 10px rgb(241, 241, 241);
  border-radius: 10px;
}
.ddl {
  font-size: 12px;
  display: none;
  width: 100%;
  text-align: center;
}
</style>
