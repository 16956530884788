<template>
  <div>
    <li class="chainlist">
      <img :src="shus.img" alt="" />
      <div class="listbom">
        <p>{{ shus.title }}</p>
      </div>
      <div class="buts">
        <button style="background-color: rgb(33, 151, 0)" @click="dis = !dis">
          修改
        </button>
        <button style="background-color: rgb(255, 49, 49)" @click="deletes">
          删除
        </button>
      </div>
    </li>
    <div class="objbox" v-if="dis">
      <div class="obj">
        <p class="title">修改信息</p>
        <p class="int">
          URL地址:
          <input
            type="text"
            v-model="shu.url"
            placeholder="请输入点击跳转地址"
          />
        </p>
        <p class="int">
          IMG地址:
          <input type="text" v-model="shu.img" placeholder="请输入广告图直链" />
        </p>
        <p class="int">
          广告标题:
          <input type="text" v-model="shu.title" placeholder="请输入广告标题" />
        </p>
        <p class="int">
          保证金额:
          <input type="text" v-model="shu.money" placeholder="请输入广告金额" />
        </p>
        <button class="but" @click="newpost">修改</button>
        <div class="back" @click="dis = !dis">
          <i class="iconfont icon-guanbi-copy"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import qs from 'qs'
import { Toast } from 'vant'
export default {
  props: ['shus'],
  data () {
    return {
      dis: false,
      shu: {
        img: '',
        title: '',
        money: '',
        url: '',
        token: '',
        id: ''
      }
    }
  },
  methods: {
    async newpost () {
      /* const postData = {} */
      this.shu.token = localStorage.getItem('xdnet_token')
      /* this.shu.id =  */
      const { data: over } = await this.$http.post(
        'admin/chainxiu',
        qs.stringify(this.shu)
      )
      /* 数据判断逻辑 */
      if (over.error_code === 1001) {
        this.$router.go(0)
        Toast.success('操作成功')
      } else if (over.data === 'token不存在数据库中--->已拦截') {
        localStorage.removeItem('xdnet_token')
        this.$router.push('/admin')
      } else if (over.error_code === 1002) {
        this.$router.push('/sos')
      } else if (over.error_code === 1007) {
        Toast('请将数据填写完整')
      } else {
        Toast('未知错误' + over.error_code)
      }
    },
    /* 删除成功 */
    async deletes () {
      const postData = {}
      postData.token = localStorage.getItem('xdnet_token')
      postData.id = this.shus.id
      const { data: over } = await this.$http.post(
        '/admin/chaindelete',
        qs.stringify(postData)
      )
      /* 数据判断逻辑 */
      if (over.error_code === 1001) {
        this.$router.go(0)
        Toast.success('操作成功')
      } else if (over.error_code === 1007) {
        Toast('请将数据填写完整')
      } else if (over.error_code === 1002) {
        this.$router.push('/sos')
      } else if (over.data === 'token不存在数据库中--->已拦截') {
        localStorage.removeItem('xdnet_token')
        this.$router.push('/admin')
      } else {
        Toast('未知错误' + over.error_code)
      }
    }
  },
  created () {
    this.shu.img = this.shus.img
    this.shu.title = this.shus.title
    this.shu.url = this.shus.url
    this.shu.money = this.shus.money
    this.shu.id = this.shus.id
    /*     console.log(this.shus.id) */
  }
}
</script>

<style lang="less" scoped>
.buts {
  height: 50px;
  display: flex;
  position: absolute;
  bottom: 10px;
  right: 0;
  justify-content: center;
  button {
    width: 50px;
    height: 23px;
    border-radius: 4px;
    border: none;
    margin: 5px;
    color: white;
    font-size: 12px;
  }
}
.chainlist {
  width: 94%;
  height: 130px;
  overflow: hidden;
  margin: 6px auto;
  background-color: white;
  border-radius: 5px;
  position: relative;
  img {
    width: 100%;
    height: 100%;
  }
  .listbom {
    width: 100%;
    height: 25px;
    position: absolute;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.413);
    p {
      height: 20px;
      line-height: 25px;
      margin-left: 10px;
      margin-right: 10px;
      color: white;
      font-size: 12px;
      display: inline-block;
      text-align: center;
      font-weight: 600;
    }
  }
}
.objbox {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.349);
  position: fixed;
  top: 0;
  z-index: 3;
  .obj {
    position: relative;
    .back {
      width: 50px;
      height: 50px;
      line-height: 50px;
      text-align: center;
      position: absolute;
      top: 0;
      right: 0;
      i {
        font-size: 17px;
      }
    }
    .but {
      width: 80px;
      height: 25px;
      border-radius: 6px;
      border: none;
      background-color: rgb(19, 129, 255);
      color: white;
      font-size: 13px;
      display: block;
      margin: 10px auto;
    }
    width: 70%;
    background-color: white;
    padding: 15px;
    margin: 200px auto;
    border-radius: 5px;
    .int {
      height: 40px;
      display: flex;
      line-height: 40px;
      text-align: center;
      font-size: 12px;
      color: rgb(91, 91, 91);
      input {
        border: none;
        /*         width: 100%; */
        margin-left: 10px;
      }
    }
    .title {
      width: 100%;
      font-size: 14px;
      font-weight: 600;
      text-align: center;
      margin-bottom: 10px;
    }
  }
}
</style>
