<template>
  <div class="q">
    <!-- 顶部title -->
    <div class="top">
      <p>小豆信誉-后台管理</p>
      <div class="back">
        <i
          class="iconfont icon-liebiao"
          v-if="!navtype"
          @click="navtype = !navtype"
        ></i>
        <i
          class="iconfont icon-guanbi"
          v-if="navtype"
          @click="navtype = !navtype"
        ></i>
      </div>
    </div>
    <!-- 侧边导航栏 -->
    <div class="navbox" v-if="navtype" @click="navtype = !navtype">
      <div class="nav">
        <ul class="navul">
          <navlist
            v-for="itme in navlist"
            :key="itme.title"
            :title="itme.title"
            :url="itme.url"
            @click="navtype = !navtype"
          ></navlist>
        </ul>
      </div>
    </div>

    <router-view></router-view>
  </div>
</template>

<script>
import navlist from '@/components/admin/component/navlist.vue'
export default {
  data () {
    return {
      navtype: false,
      navlist: [
        { title: '信息总览', url: '/admin/home' },
        { title: '人员编辑', url: '/admin/user' },
        { title: '新增人员', url: '/admin/newuser' },
        { title: '审核管理', url: '/admin/examine' },
        { title: '诚信商家', url: '/admin/chain' },
        { title: '站点设置', url: '/admin/over' }
        /* { title: '授权站点', url: 'http://shou.xudounet.com' }, */
        /* { title: '联系开发者', url: '/admin/qw' } */
      ]
    }
  },
  methods: {},
  components: {
    navlist
  }
}
</script>

<style lang="less" scoped>
.q {
  width: 100%;
  min-height: 800px;
  background-color: rgb(249, 249, 249);
  position: relative;
  .navbox {
    width: 100%;
    height: 100%;
    background-color: rgba(2, 2, 2, 0.363);
    position: absolute;
    top: 45px;
    left: 0;
    z-index: 100;
    .nav {
      width: 50%;
      height: 100%;
      background-color: white;
      overflow: hidden;
      .navul {
        width: 100%;
        /*       height: 100%; */
        margin-top: 10px;
      }
    }
  }
  .top {
    width: 100%;
    height: 45px;
    background-color: rgb(57, 126, 255);
    position: relative;
    .back {
      width: 50px;
      height: 45px;
      line-height: 45px;
      text-align: center;

      position: absolute;
      left: 0;
      top: 0;
      i {
        font-size: 20px !important;
      }
    }
    p {
      width: 100%;
      height: 100%;
      line-height: 45px;
      text-align: center;
      color: white;
      font-size: 13px;
      font-weight: 600;
    }
  }
}
</style>
