import { render, staticRenderFns } from "./postlog.vue?vue&type=template&id=c868d1e0&scoped=true&"
import script from "./postlog.vue?vue&type=script&lang=js&"
export * from "./postlog.vue?vue&type=script&lang=js&"
import style0 from "./postlog.vue?vue&type=style&index=0&id=c868d1e0&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c868d1e0",
  null
  
)

export default component.exports