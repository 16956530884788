<template>
<div class="q">
        <li class="tj-list">
            <router-link to="/admin/newusers/1">
                 <div class="left">
                <p class="title-p">手动添加确认诈骗人员信息</p>
                <p class="title-s">添加诈骗人员</p>
            </div>
            </router-link>
            <div class="reg">
                <div class="box">
                    <i class="iconfont icon-about_guanyu"></i>
                </div>
            </div>
        </li>
        <li class="tj-list">
            <router-link to="/admin/newusers/2">
            <div class="left">
                <p class="title-p">手动添加疑似诈骗人员信息</p>
                <p class="title-s">添加疑似诈骗</p>
            </div>
            </router-link>
            <div class="reg">
                <div class="box">
                    <i class="iconfont icon-integration_jifen"></i>
                </div>
            </div>
        </li>
        <li class="tj-list">
            <router-link to="/admin/newusers/3">
            <div class="left">
                <p class="title-p">手动添加安全认证人员信息</p>
                <p class="title-s">添加安全认证</p>
            </div>
            </router-link>
            <div class="reg">
                <div class="box">
                    <i class="iconfont icon-safety_anquan"></i>
                </div>
            </div>
        </li>
    </div>
</template>

<script>
export default {

}
</script>

<style lang='less' scoped>
.q{
    width: 100%;
    height: 100%;
    background-color: #FAFBFE;

}
.tj-list{
    width: 90%;
    height: 120px;
    background-color: white;
    border-radius: 6px;
    margin: 10px auto;
    display: flex;
}
.left{
    width: 100%;
    height: 100%;
    padding: 20px;
}
.title-p{
    font-size: 12px;
    color: #575757;
    margin-top: 10px;
}
.title-s{
    font-size: 18px;
    color: #363636;
    font-weight: 700;
    margin-top: 20px;
}
.reg{
    width: 100px;
    height: 100px;
    position: relative;

}
.box{
    width: 50px;
    height: 50px;
    position: absolute;
    top: 20px;
    right: 20px;
    /* background-color: #8eb1ff; */
    border-radius: 10px;
    text-align: center;

    line-height: 50px;
}
.box i{
    font-size: 40px!important;
}

</style>
