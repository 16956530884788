<template>
  <div class="q">
    <div class="ver">
      <div class="top">
        <ul class="from">
          <p class="title-from">修改信息</p>
          <li class="postlist">
            <div class="postlistibox">
              <i class="iconfont icon-qq"></i>
            </div>
            <input
              type="text"
              v-model="shus.qq"
              placeholder="请输入对方QQ号（不知道不填）"
            />
          </li>
          <p class="fgx"></p>
          <li class="postlist">
            <div class="postlistibox">
              <i class="iconfont icon-weixin"></i>
            </div>
            <input
              type="text"
              v-model="shus.wx"
              placeholder="请输入对方微信号（不知道不填）"
            />
          </li>
          <p class="fgx"></p>
          <li class="postlist">
            <div class="postlistibox">
              <i class="iconfont icon-dianhua"></i>
            </div>
            <input
              type="text"
              v-model="shus.ipone"
              placeholder="请输入对方手机号（不知道不填）"
            />
          </li>
        </ul>
      </div>
      <ul class="from">
        <li class="postlist" v-if="type !== 'A'">
          <div class="postlistibox">
            <i class="iconfont icon-jinggaoxuanzhong"></i>
          </div>
          <input
            type="text"
            v-model="shus.money"
            placeholder="请输入被骗金额"
          />
        </li>
        <li class="postlist" v-if="type === 'A'">
          <div class="postlistibox">
            <i class="iconfont icon-safety_anquan"></i>
          </div>
          <input type="text" v-model="shus.att" placeholder="请输入认证金额" />
        </li>
        <li class="postlist">
          <div class="postlistibox">
            <i class="iconfont icon-safety_anquan"></i>
          </div>
          <input
            type="text"
            v-model="shus.grade"
            placeholder="请输入编号(不懂勿改)"
          />
        </li>
      </ul>
      <ul class="from" v-if="type !== 'A'">
        <p>请输入详细情况</p>
        <textarea
          cols="10"
          rows="5"
          placeholder="诈骗过程简述，诈骗类型简述"
          v-model="shus.deta"
        ></textarea>
      </ul>
      <ul class="from" v-if="type !== 'A'">
        <p>请上传3-6张证据截图</p>
        <ul class="formUl">
          <li v-for="itme in shus.img" :key="itme.id">
            <img :src="itme" alt="" />
          </li>
          <li>
            <img src="../../../assets/image/jia.png" alt="" />
            <input
              type="file"
              name="img"
              class="fil"
              @change="postimg($event)"
            />
          </li>
        </ul>
      </ul>

      <button class="but" @click="post">确认修改</button>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line
import qs from "qs";
import { Toast } from 'vant'

export default {
  name: 'user_post',
  props: ['type'],
  data () {
    return {
      shus: {
        qq: '',
        wx: '',
        ipone: '',
        money: 0,
        deta: '',
        img: [],
        grade: 'A',
        att: '',
        type: 1,
        gid: '',
        time: ''
      },
      fileList: []
    }
  },
  methods: {
    /* 图片上传函数 */
    async postimg (e) {
      const file = e.target.files[0]
      if (file.length <= 0) {
        return Toast('请选择文件')
      }
      const fd = new FormData()
      fd.append('img', file, file.name)
      /* post提交数据 */
      const { data: over } = await this.$http({
        method: 'POST',
        url: '/user/upimg',
        headers: { 'content-type': 'multipart/form-data' },
        data: fd
      })
      console.log(over)
      if (over.error_code === '1001') {
        Toast.success('操作成功')

        this.shus.img.push(over.data)
      } else if (over.error_code === '1002') {
        this.$router.push('/sos')
      } else {
        Toast('未知错误' + over.error_code)
      }
    },
    /* 上传数据函数 */
    async post () {
      const posts = {}
      posts.grade = this.shus.grade
      posts.gid = JSON.parse(localStorage.getItem('userlist_config')).gid
      posts.time = this.shus.time
      if (this.shus.qq.length > 5) {
        posts.qq = this.shus.qq
      }
      if (this.shus.wx.length > 5) {
        posts.wx = this.shus.wx
      }
      if (this.shus.ipone.length > 5) {
        posts.ipone = this.shus.ipone
      }
      /* 判断人员类型 */
      if (JSON.parse(localStorage.getItem('userlist_config')).type === 1) {
        posts.att = 0
        posts.deta = this.shus.deta
        posts.img = this.shus.img
        posts.token = localStorage.getItem('xdnet_token')
        posts.type = 1
        posts.money = this.shus.money
      } else {
        posts.att = this.shus.att
        posts.money = '0'
        posts.deta = '123'
        posts.img = ['http://123']
        posts.token = localStorage.getItem('xdnet_token')
        posts.type = 2
      }

      const { data: over } = await this.$http.post(
        '/admin/usermod',
        qs.stringify(posts)
      )
      if (over.error_code === 1001) {
        Toast.success('操作成功')

        /* this.shus.img.push(over.data) */
      } else if (over.error_code === 1002) {
        this.$router.push('/sos')
      } else {
        Toast('未知错误' + over.error_code)
      }
    },
    async gets () {
      const datapost = {}
      datapost.gid = JSON.parse(localStorage.getItem('userlist_config')).gid
      const { data: over } = await this.$http.post(
        '/user/obtain',
        qs.stringify(datapost)
      )
      /*       console.log(over) */
      if (over.error_code === 1001) {
        this.shus.att = over.data[0].att
        this.shus.money = over.data[0].money
        this.shus.deta = over.data[0].deta
        this.shus.grade = over.data[0].grade
        this.shus.time = over.data[0].time
        if (over.data[0].qq) {
          this.shus.qq = over.data[0].qq
        }
        if (over.data[0].wx) {
          this.shus.wx = over.data[0].wx
        }
        if (over.data[0].ipone) {
          this.shus.ipone = over.data[0].ipone
        }
        if (over.data[0].img) {
          this.shus.img = over.data[0].img
        }
        this.shus.gid = JSON.parse(localStorage.getItem('userlist_config')).gid
      } else if (over.error_code === 1007) {
        Toast('请将数据填写完整')
      } else if (over.error_code === 1002) {
        this.$router.push('/sos')
      } else {
        Toast('未知错误' + over.error_code)
      }
    }
  },
  created () {
    this.gets()
  }
}
</script>

<style lang="less" scoped>
.q {
  width: 96%;
  min-height: 800px;
  padding: 2%;
  background-color: rgb(247, 247, 247);
  .ver {
    width: 100%;
    margin-bottom: 80px;
    .but {
      width: 200px;
      height: 40px;
      border-radius: 10px;
      margin: 10px auto;
      background-color: rgb(46, 130, 255);
      border: none;
      display: block;
      color: white;
      font-weight: 600;
    }
    .top {
      width: 100%;
      height: 100%;
      /*  background: url(../../assets/image/topback.png); */
      background-size: 100%;
      padding-top: 10px;
      border-radius: 5px;
      .from {
        width: 94%;
        /*         height: 200px; */
        background-color: white;
        border-radius: 5px;
        padding: 3%;
        .title-from {
          width: 100%;
          height: 40px;
          line-height: 40px;
          text-align: center;
          font-size: 14px;
          font-weight: 600;
          color: rgb(96, 96, 96);
        }
        .postlist {
          width: 100%;
          height: 40px;
          overflow: hidden;
          display: flex;
          .postlistibox {
            width: 50px;
            height: 100%;
            line-height: 40px;
            text-align: center;
            i {
              font-size: 20px !important;
            }
          }
          input {
            width: 100%;
            height: 100%;
            border: none;
            font-size: 13px;
          }
        }
        .fgx {
          width: 80%;
          height: 1px;
          margin: 5px auto;
          background-color: rgb(249, 249, 249);
        }
      }
    }
    .from {
      width: 94%;
      /*       height: 200px; */
      background-color: white;
      border-radius: 5px;
      padding: 3%;
      margin-top: 10px;
      .formUl {
        width: 100%;
        display: flex;
        /*           justify-content: space-between; */
        flex-wrap: wrap;
        li {
          width: 30%;
          height: 130px;
          overflow: hidden;
          border-radius: 5px;
          margin: 5px;
          box-shadow: 2px 2px 5px rgb(241, 241, 241);
          position: relative;
          .fil {
            width: 100%;
            height: 100%;
            background-color: white;
            opacity: 0;
            position: absolute;
            top: 0;
            z-index: 3;
          }
          img {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            z-index: 2;
          }
        }
      }
      p {
        width: 100%;
        height: 40px;
        line-height: 40px;
        text-align: center;
        font-size: 14px;
      }
      textarea {
        width: 100%;
        min-height: 50px;
        border: none;
        font-size: 13px;
      }
      .title-from {
        width: 100%;
        height: 40px;
        line-height: 40px;
        text-align: center;
        font-size: 14px;
        font-weight: 600;
        color: rgb(96, 96, 96);
      }
      .postlist {
        width: 100%;
        height: 40px;
        overflow: hidden;
        display: flex;
        .postlistibox {
          width: 50px;
          height: 100%;
          line-height: 40px;
          text-align: center;
          i {
            font-size: 21px !important;
          }
        }
        input {
          width: 100%;
          height: 100%;
          border: none;
          font-size: 13px;
        }
      }
      .fgx {
        width: 80%;
        height: 1px;
        margin: 5px auto;
        background-color: rgb(249, 249, 249);
      }
    }
  }
}
</style>
