import Vue from 'vue'
import App from './App.vue'
import router from './router'

/* 导入全局共享css样式表 */
import '@/assets/css/ty.css'

/* 导入axios */
import axios from 'axios'

/* 导入全局底部导航组件 */
import bottomnav from '@/components/user/bottom.vue'
/* 导入全局版权以及备案号 */
import conp from '@/components/conp.vue'

// eslint disable next line
import Vant from 'vant'
import 'vant/lib/index.css'

Vue.use(Vant)

Vue.component('bottomnav', bottomnav)
Vue.component('conps', conp)

/* 将axios挂在到VUE实例上 */
Vue.prototype.$http = axios
const aa = {}

function req () {
  // eslint-disable-next-line
  $.ajax({
    cache: false,
    async: false,
    type: 'get', // 请求类型
    datatype: 'json', // 数据类型
    url: '../static/apiurl.json', // 向哪⾥请求
    success: function (data) {
      aa.apiurl = data.url
    }
  })
}
req()
axios.defaults.baseURL = aa.apiurl
Vue.config.productionTip = false

Vue.directive('title', {
  inserted: function (el, binding) {
    /*     console.log(el) */
    document.title = el.dataset.title
  }
})

new Vue({
  router,
  render: (h) => h(App)
}).$mount('#app')
