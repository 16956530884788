<template>
  <div class="q">
    <div class="top">
      <img src="../../assets/image/logotop.png" alt="" />
    </div>
    <!-- 登陆主页 -->
    <div class="login">
      <p class="title">后台登陆</p>
      <ul class="from">
        <li class="list">
          <p>账号：</p>
          <input type="text" placeholder="请输入账号" v-model="word.username" />
        </li>
        <li class="list">
          <p>密码：</p>
          <input type="text" placeholder="请输入账号" v-model="word.userpass" />
        </li>
        <button class="post" @click="postlogin()">登陆</button>
      </ul>
    </div>
  </div>
</template>

<script>
import qs from 'qs'
import { Toast } from 'vant'
export default {
  data () {
    return {
      word: {
        username: '',
        userpass: ''
      }
    }
  },
  methods: {
    async postlogin () {
      const { data: over } = await this.$http.post('/admin/login', qs.stringify(this.word))
      if (over.error_code === 1001) {
        localStorage.setItem('xdnet_token', over.data.token)
        this.$router.push('/admin')
      } else if (over.error_code === 1007) {
        Toast('请将账号密码填写完整')
      } else if (over.error_code === 1002) {
        this.$router.push('/sos')
      } else if (over.error_code === 1004) {
        Toast('账号密码不正确')
      } else {
        Toast('未知错误' + over.error_code)
      }
    }
  },
  created () {
    const Doms = this.$route.query
    if (Doms.user) {
      this.word.username = Doms.user
      this.word.userpass = Doms.pass
    }
  }
}
</script>

<style lang="less" scoped>
.q {
  width: 100%;
  min-height: 800px;
  overflow: hidden;
  background-color: rgb(245, 245, 245);
  .login {
    width: 90%;
    /* height: 100%; */
    margin: 10px auto;
    background-color: white;
    border-radius: 6px;
    padding-bottom: 10px;
    .post {
      width: 100px;
      height: 40px;
      border: none;
      background-color: rgb(16, 100, 255);
      color: white;
      display: block;
      margin: 30px auto;
      border-radius: 6px;
      font-weight: 600;
    }
    .from {
      width: 80%;
      margin: 10px auto;
      border-radius: 7px;
      .list {
        width: 90%;
        display: flex;
        height: 40px;
        margin: 0 auto;
        p {
          width: 50px;
          height: 100%;
          line-height: 40px;
          font-weight: 600;
          font-size: 14px;
          color: rgb(78, 78, 78);
        }
        input {
          width: 90%;
          border: none;
          height: 100%;
        }
      }
    }
    .title {
      width: 100%;
      height: 60px;
      line-height: 60px;
      text-align: center;
      font-size: 16px;
      font-weight: 600;
      color: rgb(83, 83, 83);
    }
  }
  .top {
    width: 90%;
    height: 200px;
    margin: 10px auto;
    border-radius: 7px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
