<template>
  <li class="navlist">
    <router-link class="listbut" :to="url"
      ><p>{{title}}</p>
      <div class="icon">
        <i class="iconfont icon-qianjin"></i>
      </div>
    </router-link>
  </li>
</template>

<script>
export default {
  props: ['title', 'url']
}
</script>

<style lang='less' scoped>
.navlist {
        width: 80%;
        margin: 5px auto;
        overflow: hidden;
        position: relative;
        height: 45px;
        .listbut {
          width: 100%;
          height: 100%;
          display: block;
          p {
            /*             width: 100%; */
            height: 45px;
            line-height: 45px;
            display: inline-block;
            text-align: center;
            font-size: 14px;
            font-weight: 600;
            color: rgb(78, 78, 78);
          }
          .icon {
            position: absolute;
            right: 0;
            top: 0;
            width: 45px;
            height: 45px;
            line-height: 45px;
            text-align: center;
            i {
              font-size: 13px;
            }
          }
        }
      }
</style>
