<template>
  <div class="bot">
    <div class="bot-nav">
      <router-link to="/index" class="nav-link"
        ><i class="iconfont icon-shouye"></i>首页</router-link
      >
    </div>
    <div class="bot-nav">
      <router-link to="/post" class="nav-link"
        ><i class="iconfont icon-yujing"></i>举报</router-link
      >
    </div>
    <div class="bot-nav">
      <router-link to="/grade" class="nav-link"
        ><i class="iconfont icon-xunzhang"></i>等级</router-link
      >
    </div>
    <div class="bot-nav">
      <router-link to="/chain" class="nav-link"
        ><i class="iconfont icon-dianpu"></i>诚信商家</router-link
      >
    </div>
  </div>
</template>

<script>
export default {}
</script>
<style lang="less" scoped>
.bot {
  width: 100%;
  height: 50px;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  background-color: white;
  position: fixed;
  bottom: 0;
  padding-top: 8px;
  z-index: 10000;
  left: 50%;
  transform: translate(-50%);
  max-width: 460px;
  margin: 0 auto;
  overflow: hidden;
  .bot-nav {
    width: 100%;
    height: 100%;
    text-align: center;

    .nav-link {
      /*         margin-top: 5px; */
      font-size: 13px;
      display: block;
      text-decoration-line: none;
      color: rgb(100, 100, 100);
      .iconfont {
        font-size: 26px;
        display: block;
      }
    }
  }
}
</style>
