<template>
  <div class="q">
    <div class="ver">
      <div class="top">
        <ul class="from">
          <p class="title-from">提交举报</p>
          <li class="postlist">
            <div class="postlistibox">
              <i class="iconfont icon-qq"></i>
            </div>
            <input
              type="text"
              v-model="shus.qq"
              placeholder="请输入对方QQ号（不知道不填）"
            />
          </li>
          <p class="fgx"></p>
          <li class="postlist">
            <div class="postlistibox">
              <i class="iconfont icon-weixin"></i>
            </div>
            <input
              type="text"
              v-model="shus.wx"
              placeholder="请输入对方微信号（不知道不填）"
            />
          </li>
          <p class="fgx"></p>
          <li class="postlist">
            <div class="postlistibox">
              <i class="iconfont icon-dianhua"></i>
            </div>
            <input
              type="text"
              v-model="shus.ip"
              placeholder="请输入对方手机号（不知道不填）"
            />
          </li>
        </ul>
      </div>
      <ul class="from">
        <li class="postlist">
          <div class="postlistibox">
            <i class="iconfont icon-jinggaoxuanzhong"></i>
          </div>
          <input
            type="text"
            v-model="shus.money"
            placeholder="请输入被骗金额"
          />
        </li>
      </ul>
      <ul class="from">
        <p>请输入详细情况</p>
        <textarea
          cols="10"
          rows="5"
          placeholder="诈骗过程简述，诈骗类型简述"
          v-model="shus.deta"
        ></textarea>
      </ul>
      <ul class="from">
        <p>请上传3-6张证据截图</p>
        <ul class="formUl">
          <li v-for="itme in shus.img" :key="itme.id">
            <img :src="itme" alt="" />
          </li>
          <li>
            <img src="../../assets/image/jia.png" alt="" />
            <input
              type="file"
              name="img"
              class="fil"
              @change="postimg($event)"
            />
          </li>
        </ul>
      </ul>
      <!--  <van-uploader
          v-model="fileList"
          multiple
          :max-count="6"
          :after-read="afterRead"
        />
      </ul> -->
      <button class="but" @click="post">确认举报</button>
    </div>
    <!-- 底部版权组件 -->
    <conps></conps>
    <!-- 底部导航组件 -->
    <bottomnav></bottomnav>
    <!-- 查看我的举报 -->
    <div class="mypost">
      <router-link class="myposta" to="/post/log"> 举报记录 </router-link>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line
import qs from "qs";
import { Toast } from 'vant'
export default {
  name: 'user_post',
  data () {
    return {
      shus: {
        qq: '',
        wx: '',
        ip: '',
        money: '',
        deta: '',
        img: [],
        grade: 'D'
      },
      fileList: []
    }
  },
  methods: {
    /* 图片上传函数 */
    async postimg (e) {
      const file = e.target.files[0]
      if (!file) {
        return Toast('请选择文件')
      }
      const fd = new FormData()
      fd.append('img', file, file.name)
      /* post提交数据 */
      const { data: over } = await this.$http({
        method: 'POST',
        url: '/user/upimg',
        headers: { 'content-type': 'multipart/form-data' },
        data: fd
      })
      if (over.error_code === '1001') {
        this.shus.img.push(over.data)
      } else if (over.error_code === '1002') {
        this.$router.push('/sos')
      } else {
        Toast('未知错误' + over.error_code)
      }
    },
    /* 上传数据函数 */
    async post () {
      this.shus.gid = 'XDNET' + new Date().getTime()
      /* 判断图片数量并且终止程序逻辑运行 */
      if (this.shus.img.length < 3 || this.shus.img.length > 6) {
        return Toast('请上传3-6张证据截图')
      }
      if (this.shus.ip.length < 1) {
        /* 若图片文件小于1 则跳过 */
        console.log('ok')
      } else if (this.shus.ip.length !== 11) {
        /* 判断手机号是否留空 */
        return Toast('请输入正确的手机号或留空')
      } else if (this.shus.ip.length === 11) {
        /* 提交手机号 */
        this.shus.ipone = this.shus.ip
      }
      const { data: over } = await this.$http.post(
        '/user/userpost',
        qs.stringify(this.shus)
      )
      if (over.error_code === '成功') {
        /* this.shus.img.push(over.data) */

        Toast('举报成功，进入审核阶段')
        this.$router.push('/post/log')
        /* this.$router.go(0) */
        /* 存入本地缓存文件 */
        if (!JSON.parse(localStorage.getItem('xdnet_postlog'))) {
          const data = []
          data[0] = this.shus
          console.log(data)
          localStorage.setItem('xdnet_postlog', JSON.stringify(data))
        } else {
          const data = JSON.parse(localStorage.getItem('xdnet_postlog'))
          data[data.length] = this.shus
          localStorage.setItem('xdnet_postlog', JSON.stringify(data))
        }

        this.shus.img = []
        this.shus.deta = ''
        this.shus.money = ''
        this.shus.ipone = ''
        this.shus.qq = ''
        this.shus.wx = ''
      } else if (over.error_code === 1007) {
        Toast('请将数据填写完整')
      } else if (over.error_code === '1002') {
        this.$router.push('/sos')
      } else {
        Toast('未知错误' + over.error_code)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.mypost {
  width: 60px;
  height: 60px;
  border-radius: 150px;
  background-color: rgb(42, 124, 255);
  color: white;
  line-height: 60px;
  text-align: center;
  position: fixed;
  bottom: 100px;
  right: 10px;
  font-size: 12px;
  font-weight: 600;
  z-index: 10;
  .myposta {
    color: white;
    line-height: 60px;
    text-align: center;
    font-size: 12px;
    font-weight: 600;
    display: block;
  }
}
.q {
  width: 96%;
  min-height: 800px;
  padding: 2%;
  background-color: rgb(247, 247, 247);
  .ver {
    width: 100%;
    margin-bottom: 80px;
    .but {
      width: 200px;
      height: 40px;
      border-radius: 10px;
      margin: 10px auto;
      background-color: rgb(46, 130, 255);
      border: none;
      display: block;
      color: white;
      font-weight: 600;
    }
    .top {
      width: 100%;
      height: 100%;
      background: url(../../assets/image/topback.png);
      background-size: 100%;
      padding-top: 130px;
      border-radius: 5px;
      .from {
        width: 94%;
        /*         height: 200px; */
        background-color: white;
        border-radius: 5px;
        padding: 3%;
        .title-from {
          width: 100%;
          height: 40px;
          line-height: 40px;
          text-align: center;
          font-size: 14px;
          font-weight: 600;
          color: rgb(96, 96, 96);
        }
        .postlist {
          width: 100%;
          height: 40px;
          overflow: hidden;
          display: flex;
          .postlistibox {
            width: 50px;
            height: 100%;
            line-height: 40px;
            text-align: center;
            i {
              font-size: 25px !important;
            }
          }
          input {
            width: 100%;
            height: 100%;
            border: none;
          }
        }
        .fgx {
          width: 80%;
          height: 1px;
          margin: 5px auto;
          background-color: rgb(249, 249, 249);
        }
      }
    }
    .from {
      width: 94%;
      /*       height: 200px; */
      background-color: white;
      border-radius: 5px;
      padding: 3%;
      margin-top: 10px;
      .formUl {
        width: 100%;
        display: flex;
        /*           justify-content: space-between; */
        flex-wrap: wrap;
        li {
          width: 30%;
          height: 130px;
          overflow: hidden;
          border-radius: 5px;
          margin: 5px;
          box-shadow: 2px 2px 5px rgb(241, 241, 241);
          position: relative;
          .fil {
            width: 100%;
            height: 100%;
            background-color: white;
            opacity: 0;
            position: absolute;
            top: 0;
            z-index: 3;
          }
          img {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            z-index: 2;
          }
        }
      }
      p {
        width: 100%;
        height: 40px;
        line-height: 40px;
        text-align: center;
        font-size: 14px;
      }
      textarea {
        width: 100%;
        min-height: 50px;
        border: none;
      }
      .title-from {
        width: 100%;
        height: 40px;
        line-height: 40px;
        text-align: center;
        font-size: 14px;
        font-weight: 600;
        color: rgb(96, 96, 96);
      }
      .postlist {
        width: 100%;
        height: 40px;
        overflow: hidden;
        display: flex;
        .postlistibox {
          width: 50px;
          height: 100%;
          line-height: 40px;
          text-align: center;
          i {
            font-size: 25px !important;
          }
        }
        input {
          width: 100%;
          height: 100%;
          border: none;
        }
      }
      .fgx {
        width: 80%;
        height: 1px;
        margin: 5px auto;
        background-color: rgb(249, 249, 249);
      }
    }
  }
}
</style>
