<template>
  <div class="conpq">
    <p>版权©<a :href="conps.url">{{conps.ban}}</a></p>
    <p>{{conps.beia}}</p>
  </div>
</template>

<script>
/* import qs from 'qs' */
import { Toast } from 'vant'
export default {
  data () {
    return {
      conps: {
        id: 1,
        ban: '',
        beia: '',
        url: '#'
      }
    }
  },
  methods: {
    async gets () {
      const { data: over } = await this.$http.post('/user/over')
      if (over.error_code === 1001) {
        this.conps.ban = over.data.copyright
        this.conps.beia = over.data.filing
      } else if (over.error_code === 1002) {
        this.$router.push('/sos')
      } else {
        Toast('未知错误' + over.error_code)
      }
    }
  },
  created () {
    this.gets()
  }
}
</script>

<style lang="less" scoped>
.conpq {
  width: 100%;
  margin-bottom: 60px;
  margin-top: 100px;
  p {
    width: 100%;
    text-align: center;
    font-size: 12px;
    color: rgb(112, 112, 112);
  }
}
</style>
