<template>
  <div class="q">
    <!-- 顶部搜索区域 -->
    <!-- 顶部标题栏 -->
    <div class="topbar">
      <p class="toptitle">搜索详情</p>
      <div class="goback" @click="$router.back()">
        <i class="iconfont icon-back"></i>
      </div>
    </div>
    <!-- 底部人员信息列表 -->
    <div class="plist">
      <ul class="plist-ul">
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="gets"
          :error.sync="error"
          error-text="请求失败，点击重新加载"
        >        <li
          class="plist-li"
          v-for="itme in lists"
          :key="itme.id"
          @click="listclick(itme)"
        >
          <div class="list-i">
            <img
              :src="
                'http://q.qlogo.cn/headimg_dl?dst_uin=' +
                itme.qq +
                '&spec=640&img_type=jpg'
              "
              alt=""
              class="list-t"
            />
          </div>
          <div class="list-x">
            <div class="hm-w">
              <p class="hm">
                <span>QQ号</span>：<span>{{ itme.qq }}</span>
              </p>
              <p class="hm">
                <span>微信号</span>：<span>{{ itme.wx }}</span>
              </p>
            </div>
            <p class="rq" v-if="itme.time">
              <span>存档日期</span>：<span>{{ itme.time }}</span>
            </p>
          </div>
          <img
            src="https://s1.ax1x.com/2022/04/24/L5Dmb6.png"
            alt=""
            class="list-bq"
            v-if="itme.grade == 'A'"
          />
          <img
            src="https://s1.ax1x.com/2022/04/24/L5Bb4g.png"
            alt=""
            class="list-bq"
            v-if="itme.grade == 'B'"
          />
          <img
            src="https://s1.ax1x.com/2022/04/24/L5B7E8.png"
            alt=""
            class="list-bq"
            v-if="itme.grade == 'C'"
          />
          <img
            src="https://s1.ax1x.com/2022/04/24/L5BwcR.png"
            alt=""
            class="list-bq"
            v-if="itme.grade == 'D'"
          />
          <img src="../../assets/image/list-s.png" alt="" class="list-bq" />
        </li>
         <!--  <list v-for="itme in lists" :key="itme.id" :shus="itme"></list> -->
          <!--         <van-cell v-for="item in lists" :key="item" :title="item" /> -->
        </van-list>

      </ul>
    </div>
    <!-- 底部版权组件 -->
    <conps></conps>

  </div>
</template>

<script>
/* 导入qs */
/* import qs from 'qs' */
export default {
  data () {
    return {
      zh: '',
      lxboxr: 0,
      lists: [],
      page: 1,
      loading: false,
      finished: false,
      error: false
    }
  },
  methods: {

    /* 点击列表事件 */
    listclick (itme) {
      const imgs = JSON.stringify(itme)
      // eslint-disable-next-line
      localStorage.setItem("xdnet_dateils", imgs);
      this.$router.push('/dateils')
    },
    /* 获取数据函数 */
    async gets () {
      const over = JSON.parse(localStorage.getItem('xdnet_sch'))
      /*       console.log(over) */
      if (over) {
        if (over.length < 10) {
          this.finished = true
        }
        /* 合并原有数组数据 */
        this.lists = [...this.lists, ...over]
        /* 页码数自动加1 */
        this.page += 1
        this.loading = false
      } else {
        this.loading = false
        this.error = true
      }
      /* if (over.error_code === 1001) {
        this.list = over.data
      } */
    }
  },
  created () {
    /*     this.gets() */
  }
}
</script>

<style lang="less" scoped>
  .topbar {
    width: 100%;
    height: 50px;
    background-color: rgb(61, 116, 255);
    position: relative;
    .toptitle {
      width: 100%;
      height: 50px;
      line-height: 50px;
      text-align: center;
      font-size: 14px;
      color: rgb(255, 255, 255);
      font-weight: 600;
    }
    .goback {
      width: 50px;
      height: 50px;
      text-align: center;
      line-height: 50px;
      position: absolute;
      top: 0;
      left: 0;
      i {
        font-size: 22px;
      }
    }
  }
.q {
  width: 100%;
  height: 100%;
  background-color: rgb(247, 247, 247);
  overflow: hidden;
  .plist {
    min-height: 600px;
    margin-top: 20px;
    .plist-ul {
        width: 95%;
        margin: 10px auto;
      .plist-li {
        width: 100%;
        height: 100px;
        border-radius: 5px;
        box-shadow: 4px 4px 10px rgb(241, 241, 241);
        margin-bottom: 9px;
        display: flex;
        position: relative;
        overflow: hidden;
        background-color: white;
      }
      .list-i {
        width: 100px;
        height: 100%;
        text-align: center;
        line-height: 100px;
      }
      .list-t {
        border-radius: 150px;
        width: 35px;
        height: 35px;
        background: rgb(124, 170, 255);
        text-align: center;
        display: inline-block;
      }
      .list-bq {
        width: 90px;
        /*  height: 90px; */
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 10;
      }
      .list-x {
        width: 100%;
        height: 100%;
      }
      .hm-w {
        margin-top: 19px;
      }
      .hm {
        width: 100%;
        height: 20px;
        line-height: 20px;
        /* text-align: center; */
        color: rgb(0, 0, 0);
        font-size: 15px;
        /* font-weight: 600; */
        text-shadow: 2px 2px 5px rgb(201, 201, 201);
        /* margin-top: 30px; */
      }
      .rq {
        /* background: rgb(224, 224, 224); */
        border-radius: 5px;
        width: 200px;
        color: rgb(58, 58, 58);
        font-size: 13px;
        text-align: center;
        height: 30px;
        line-height: 30px;
        position: absolute;
        bottom: 0;
        right: 0;
      }
      .bot {
        width: 100%;
        height: 35px;
        line-height: 40px;
        text-align: center;
        background-color: white;
        box-shadow: 4px 4px 10px rgb(241, 241, 241);
        border-radius: 10px;
      }
      .ddl {
        font-size: 12px;
        display: none;
        width: 100%;
        text-align: center;
      }
    }
  }
}
</style>
