<template>
  <div class="quan">
    <!-- 顶部标题栏 -->
    <div class="toptitle">
      <div class="back" @click="$router.back()">
        <i class="iconfont icon-qianjin-copy"></i>
      </div>
      <p class="titlep">举报记录</p>
    </div>
    <!-- 信息区域 -->
    <ul class="log">
      <li class="loglist">
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="gets"
          :error.sync="error"
          error-text="请求失败，点击重新加载"
        >
          <li
            class="plist-li"
            v-for="itme in lists"
            :key="itme.id"
            @click="listclick(itme)"
          >
            <div class="list-i">
              <img
                v-if="itme.qq"
                :src="
                  'http://q.qlogo.cn/headimg_dl?dst_uin=' +
                  itme.qq +
                  '&spec=640&img_type=jpg'
                "
                alt=""
                class="list-t"
              />
              <img
                v-if="!itme.qq"
                src="
                http://img.xudounet.com/dou/za/tx.png
              "
                alt=""
                class="list-t"
              />
            </div>
            <div class="list-x">
              <div class="hm-w">
                <p class="hm">
                  <span>QQ号</span>：<span>{{ itme.qq }}</span>
                </p>
                <p class="hm">
                  <span>微信号</span>：<span>{{ itme.wx }}</span>
                </p>
              </div>
              <p class="rq" v-if="itme.time">
                <span>存档日期</span>：<span>{{ itme.time }}</span>
              </p>
            </div>
            <img
              src="http://img.xudounet.com/dou/grade/A.png"
              alt=""
              class="list-bq"
              v-if="itme.grade == 'A'"
            />
            <img
              src="http://img.xudounet.com/dou/grade/B.png"
              alt=""
              class="list-bq"
              v-if="itme.grade == 'B'"
            />
            <img
              src="http://img.xudounet.com/dou/grade/C.png"
              alt=""
              class="list-bq"
              v-if="itme.grade == 'C'"
            />
            <img
              src="http://img.xudounet.com/dou/grade/D.png"
              alt=""
              class="list-bq"
              v-if="itme.grade == 'D'"
            />
            <img src="../../assets/image/list-s.png" alt="" class="list-bq" />
          </li>
          <!--  <list v-for="itme in lists" :key="itme.id" :shus="itme"></list> -->
          <!--         <van-cell v-for="item in lists" :key="item" :title="item" /> -->
        </van-list>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data () {
    return {
      zh: '',
      lxboxr: 0,
      lists: [],
      page: 1,
      loading: false,
      finished: false,
      error: false
    }
  },
  methods: {
    gets () {
      /* 合并原有数组数据 */
      this.lists = JSON.parse(localStorage.getItem('xdnet_postlog'))
      /* 页码数自动加1 */
      this.page += 1
      this.loading = false
    },
    /* 点击列表事件 */
    listclick (itme) {
      /*       console.log(itme.id) */
      const imgs = JSON.stringify(itme)
      // eslint-disable-next-line
      localStorage.setItem("xdnet_dateils", imgs);
      this.$router.push('/dateils')
    }
  }
}
</script>

<style lang="less" scoped>
.toptitle {
  width: 100%;
  max-width: 400px;  margin: 0 auto;
  height: 45px;
  background-color: rgb(20, 110, 255);
  position: relative;
  .back {
    width: 45px;
    height: 45px;
    line-height: 45px;
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
    i {
      font-size: 18px !important;
    }
  }
  .titlep {
    width: 100%;
    height: 45px;
    line-height: 45px;
    text-align: center;
    color: white;
    font-size: 13px;
    font-weight: 600;
  }
}
.plist-li {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  height: 80px;
  border-radius: 5px;
  box-shadow: 4px 4px 10px rgb(241, 241, 241);
  margin-bottom: 9px;
  display: flex;
  position: relative;
  overflow: hidden;
  background-color: white;
}
.list-i {
  width: 100px;
  height: 100%;
  text-align: center;
  line-height: 100px;
}
.list-t {
  border-radius: 150px;
  width: 35px;
  height: 35px;
  background: rgb(124, 170, 255);
  text-align: center;
  display: inline-block;
}
.list-bq {
  width: 90px;
  /*  height: 90px; */
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10;
}
.list-x {
  width: 100%;
  height: 100%;
}
.hm-w {
  margin-top: 19px;
}
.hm {
  width: 100%;
  height: 20px;
  line-height: 20px;
  /* text-align: center; */
  color: rgb(0, 0, 0);
  font-size: 15px;
  /* font-weight: 600; */
  text-shadow: 2px 2px 5px rgb(201, 201, 201);
  /* margin-top: 30px; */
}
.rq {
  /* background: rgb(224, 224, 224); */
  border-radius: 5px;
  width: 200px;
  color: rgb(58, 58, 58);
  font-size: 13px;
  text-align: center;
  height: 30px;
  line-height: 30px;
  position: absolute;
  bottom: 0;
  right: 0;
}
.bot {
  width: 100%;
  height: 35px;
  line-height: 40px;
  text-align: center;
  background-color: white;
  box-shadow: 4px 4px 10px rgb(241, 241, 241);
  border-radius: 10px;
}
.ddl {
  font-size: 12px;
  display: none;
  width: 100%;
  text-align: center;
}
</style>
